.lg-outer .lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  will-change: visibility, opacity;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}

.lg-outer.lg-dropdown-active .lg-dropdown,
.lg-outer.lg-dropdown-active .lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible;
}

.lg-outer.lg-dropdown-active .lg-share {
  color: #fff;
}

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  will-change: visibility, opacity, transform;
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
}

.lg-outer .lg-dropdown:after {
  content: '';
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #fff;
  right: 16px;
  top: -16px;
}

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0px;
}

.lg-outer .lg-dropdown > li:hover a {
  color: #333;
}

.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}

.lg-outer .lg-share {
  position: relative;
}

.lg-outer .lg-share:after {
  content: '\e80d';
}

.lg-outer .lg-share-facebook .lg-icon {
  color: #3b5998;
}

.lg-outer .lg-share-facebook .lg-icon:after {
  content: '\e904';
}

.lg-outer .lg-share-twitter .lg-icon {
  color: #00aced;
}

.lg-outer .lg-share-twitter .lg-icon:after {
  content: '\e907';
}

.lg-outer .lg-share-pinterest .lg-icon {
  color: #cb2027;
}

.lg-outer .lg-share-pinterest .lg-icon:after {
  content: '\e906';
}
